import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shopId:'',
    shopInfo:{},
    platformType:1, // 1 熊猫收银，2 众享台球
    isTimer: false,
    menusButton: '',
    resourceCodes: '',
    changeMenus: '',
    childrenList: '',

  },
  mutations: {
    setShopId(state,value){
      state.shopId = value
    },
    setShopInfo(state,value){
      state.shopInfo = value
    },

    setPlatformType(state,value){
      state.platformType = value
    },
    setIsTimer(state) {
      state.isTimer = true;
    },
    setMenusButton(state, states) {
      state.menusButton = states;
    },
    setResourceCodes(state, states) {
        state.resourceCodes = states;
    },
    setChangeMenus(state, states) {
      console.log(states)
      state.changeMenus = states
    },
    setChildrenList(state, states) {
      console.log(states)
      state.childrenList = states
    }
  },
  actions: {
  },
  modules: {
  },
});
