































































































// @ is an alias to /src
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import vTags from "@/components/Tags.vue";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {Login, LoginUserMerchants} from "@/common/login/login";
import businessAPI from "@/common/api/business";
import {mergeStrategyAddNewParams} from "@/common/autoCollectMenus";
import PermissionSetAPI from "@/common/api/permissionSet";

@Component({
  components: {
    "v-tags": vTags,
  },
})
export default class HelloWorld extends Vue {
  private timer: any = "";
  private businessApi: any = new businessAPI();
  private permissionSet: any = new PermissionSetAPI();
  private userMenus: any = JSON.parse(localStorage.getItem("menus") as string);
  private defaultActive: any = this.$route.name;
  private deepLoopUtil: any;

  private setSelect(id: string, url: string): void {
    if (this.defaultActive != id) {
      this.defaultActive = id;
      this.$router.push(url);
    }
  }

  private created() {
    this.shopList();
  }

  private shopList(): void {
    this.businessApi.shopList({}, (res: any) => {
      this.options = res.data;
      for (let i of this.options) {
        if (i.cashier_url.split("=")[1].split("#")[0] == localStorage.getItem("ShopId")) {
          // localStorage.setItem("shopInfo", JSON.stringify(i));
          this.$store.commit('setShopInfo',i)
          this.value = i.cashier_url;
          break;
        }
      }
    });
  }

  private sumbitBacthAddNewRightMenuLoading: boolean = false
  private time: number = 0

  private handleAutoBatchAddMenus() {
    this.time++
    if (this.time < 10) {
      return
    }
    mergeStrategyAddNewParams().then((addlistParams) => {
      console.log('新增上传菜单', addlistParams)
      const resultParams = JSON.stringify(addlistParams)

      this.sumbitBacthAddNewRightMenuLoading = true

      this.permissionSet.batchAddMenuList({
        data: resultParams
      }, () => {
        this.time = 0
        this.sumbitBacthAddNewRightMenuLoading = false
        this.$message.success('批量新增成功')
      }, () => {
        this.sumbitBacthAddNewRightMenuLoading = false
      })
    })
  }

  private changeshop(): void {
    const ShopId: any = this.value.split("=")[1].split("#")[0];
    localStorage.setItem("ShopId", ShopId);
    window.location.href = this.value + "#/business";
  }

  private showMerchantsSelectionBox: boolean = false;

  private loginUserMerchantsList: LoginUserMerchants[] = [];

  private logout: Login;

  constructor() {
    super();
    this.logout = new Login();
    this.deepLoopUtil = new DeepLoop();
  }

  private options: any = [];
  private value: string = "";

  // 用户名下拉菜单选择事件
  private handleCommand(command: string): void {
    if (command == "loginout") {
      clearInterval(this.timer);
      // this.logout.logout();
      localStorage.clear();
      this.$router.push("/login");
    }
  }

  // end

  @Watch("$route")
  $setMenusList(): void {
    this.defaultActive = this.$route.name;
  }
}
